<template lang="html">
  <img class="mt-3 mb-3" src="@/assets/logo.png" alt="logo" width="200" />
  <h1 class="title">Ben je bekend met Zendesk?</h1>
  <div class="d-grid gap-2">
    <button
      v-for="answer in answers"
      v-bind:key="answer"
      type="button"
      class="btn btn-lg bg-field rounded-pill"
      :class="[
        answerChecked(answer)
          ? 'btn-primary text-light'
          : 'border border-dark text-dark',
      ]"
      @click="selectAnswer(answer)"
    >
      <i class="bi bi-square" v-if="!answerChecked(answer)"></i>
      <i class="bi bi-check-square" v-if="answerChecked(answer)"></i>
      {{ answer }}
    </button>
    <button
      type="button"
      class="btn btn-primary text-light btn-lg rounded-pill"
      @click="setQuestionTwo()"
      :disabled="!checkedAnswer"
    >
      <span v-if="!checkedAnswer">Volgende</span>
      <span v-if="checkedAnswer">Volgende</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionOneScreen",
  data: () => ({
    answers: ["Ja", "Nee"],
    checkedAnswer: null, // Change checkedAnswers to a single answer (not an array)
  }),
  methods: {
    // Select a single answer (deselect the other if one is already selected)
    selectAnswer(answer) {
      if (this.checkedAnswer === answer) {
        this.checkedAnswer = null; // Deselect if clicked again
      } else {
        this.checkedAnswer = answer; // Select the new answer
      }
    },
    // Check if the passed answer is the selected one
    answerChecked(answer) {
      return this.checkedAnswer === answer;
    },
    // Commit the selected answer to the store
    setQuestionTwo() {
      this.$store.commit("setQuestionTwo", this.checkedAnswer);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #26231d;
}
/* .bg-field {
  color: #fdf7ea !important;
} */
</style>
