<template lang="html">
  <img class="mt-3 mb-3" src="@/assets/logo.png" alt="logo" width="200" />
  <h1 class="title">Vul jouw gegevens in</h1>
  <input
    v-model="firstName"
    class="form-control field no-focusborder rounded-pill border-dark"
    type="text"
    ref="inputfield"
    placeholder="Voornaam"
    required
  />
  <input
    v-model="lastName"
    class="form-control field no-focusborder rounded-pill border-dark"
    type="text"
    ref="inputfield"
    placeholder="Achternaam"
    required
  />
  <input
    v-model="company"
    class="form-control field no-focusborder rounded-pill border-dark"
    type="text"
    ref="inputfield"
    placeholder="Bedrijf"
    required
  />
  <input
    v-model="email"
    class="form-control field no-focusborder rounded-pill border-dark"
    type="email"
    ref="inputfield"
    placeholder="E-mailadres"
    required
  />
  {{ validateEmail }}
  <input
    v-model="phone"
    class="form-control field no-focusborder rounded-pill border-dark"
    type="text"
    ref="inputfield"
    placeholder="Telefoon"
    required
  />
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg rounded-pill"
      :disabled="isDisabled == true"
      @click="setPersonalData()"
    >
      Volgende
    </button>
  </div>
</template>

<script>
export default {
  name: "PersonalDataScreen",
  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    company: null,
  }),
  computed: {
    isDisabled() {
      if (this.firstName && this.lastName && this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  methods: {
    setPersonalData() {
      this.$store.commit("setFirstName", this.firstName);
      this.$store.commit("setLastName", this.lastName);
      this.$store.commit("setCompany", this.company);
      this.$store.commit("setPhone", this.phone);
      this.$store.commit("setEmail", this.email);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #26231d;
}
.title {
  padding-bottom: 10px;
}
.field {
  /* text-transform: uppercase; */
  margin-bottom: 5px !important;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 20px;
  color: #26231d;
  background-color: #fff;
}
.field::placeholder {
  color: #96928e;
}
</style>
