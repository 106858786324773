<template lang="html">
  <img class="mt-3 mb-3" src="@/assets/logo.png" alt="logo" width="200" />
  <h1>Bedankt!</h1>
  <!-- <h2>Scan the QR code below on the claw machine for a free game.</h2> -->
  <h2>Scan de QR code op de grijpmachine en start het spel.</h2>
  <!-- <h2>Good luck!</h2> -->
  <h3>Succes!</h3>
  <div class="center">
    <img
      :src="'https://clawmachine.nl/qrcode/?s=qr&sf=8&d=' + getSavedDataId"
      width="300"
    />
  </div>
  <button
    type="button"
    class="btn btn-primary text-light btn-lg rounded-pill"
    @click="goToUrl()"
  >
    <span>Naar Zendesk.com</span>
  </button>
</template>

<script>
export default {
  name: "QrCodeScreen",
  data: () => ({}),
  computed: {
    getSavedDataId: function () {
      let savedData = this.$store.getters.getSavedData;
      if (savedData !== null) {
        return savedData._id;
      }
      return false;
    },
  },
  methods: {
    goToUrl() {
      window.location.href = "https://zendesk.com/";
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #26231d;
}
h2 {
  font-size: 25px;
  color: #26231d;
}
.field {
  /* text-transform: uppercase; */
  margin: auto;
  outline: none;
  border: none;
  width: 90%;
  padding: 10px;
  font-size: 40px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 20px;
}
.center {
  text-align: center;
}
</style>
