<template lang="html">
  <img class="mt-3 mb-3" src="@/assets/logo.png" alt="logo" width="200" />
  <h1 class="title">Ben jij al klant van Zendesk?</h1>
  <div class="d-grid gap-2">
    <button
      v-for="answer in answers"
      v-bind:key="answer"
      type="button"
      class="btn btn-lg bg-field rounded-pill"
      :class="[
        answerChecked(answer)
          ? 'btn-primary text-light'
          : 'border border-dark text-dark',
      ]"
      @click="selectAnswer(answer)"
    >
      <i class="bi bi-square" v-if="!answerChecked(answer)"></i>
      <i class="bi bi-check-square" v-if="answerChecked(answer)"></i>
      {{ answer }}
    </button>
    <button
      type="button"
      class="btn btn-primary text-light btn-lg rounded-pill"
      @click="checkedAnswer && setQuestionOne()"
      :disabled="!checkedAnswer"
    >
      <span>Volgende</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionOneScreen",
  data: () => ({
    answers: ["Ja", "Nee"],
    checkedAnswer: null, // Single answer instead of array
  }),
  methods: {
    selectAnswer(answer) {
      // Only one answer can be selected at a time
      if (this.checkedAnswer === answer) {
        this.checkedAnswer = null; // Uncheck the answer if already selected
      } else {
        this.checkedAnswer = answer; // Select the new answer
      }
    },
    answerChecked(answer) {
      return this.checkedAnswer === answer; // Check if the answer is selected
    },
    setQuestionOne() {
      this.$store.commit("setQuestionOne", this.checkedAnswer); // Pass the single answer
      if (this.checkedAnswer === "Ja") {
        console.log(this.checkedAnswer);
        this.$store.commit("setQuestionTwo", "nvt");
      }
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 30px;
  color: #26231d;
}
/* .bg-field {
  color: #fdf7ea !important;
} */
</style>
